<template>
  <div class="farmProduct">
    <img :src="icon" class="icon">
    <div class="date">
      <div>更新日期： {{date}}</div>
      <div>{{cityName}} {{areaName}}</div>
    </div>
    <div class="content">
      <div class="flex title">
        <div>品种</div>
        <div>当前价格(元)</div>
        <div>单位</div>
      </div>
      <div class="flex bottom" v-for="(item, index) in data" :key="index">
        <div>{{item.breedName}}</div>
        <div>{{item.nowAvgPrice}}</div>
        <div>{{item.unit}}</div>
      </div>    
    </div>
  </div>
</template>

<script>
import { getProductPrice } from '@/utils/api'
import {uwStatisticAction} from "../../utils/util";
export default {
  data() {
    return {
      icon: require('@/assets/img/farm/icon.png'),
      cityName: '',
      areaName: '',
      data:[],
      date:'2021-01-01'
    }
  },
  mounted() {
    this.cityName = this.$route.query.city ? this.$route.query.city : '北京市'
    this.areaName = this.$route.query.area ? this.$route.query.area : '东城区'
    this.togetProductPrice()
    uwStatisticAction('/farmProduct','农产品价格')
  },
  methods: {
    togetProductPrice(){
      getProductPrice({
        cityName: this.cityName,
        areaName: this.areaName
      }).then(res=>{
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200){
            this.data = res.data.data
            this.date = res.data.data[0].marketDate
            this.cityName = res.data.data[0].cityName
            this.areaName = res.data.data[0].areaName
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.farmProduct{
  position: relative;
  background: linear-gradient(152deg, #30BF78 0%, #0A6227 100%);
  height: 100%;
  min-height: 100%;
  .icon{
    width: 1.1rem;
    margin-left: 6.24rem;
    margin-top: 1.3rem;
  }
  .date{
    position: absolute;
    left: 0.6rem;
    top: 1.5rem;
    div{
      &:nth-child(1){
        color: #EAF7F5;
        font-size: 0.28rem;
      }
      &:nth-child(2){
        color: #fff;
        font-size: 0.48rem;
      }
    }
  }
  .flex{
    display: flex;
  }
  .content{
    position: absolute;
    top: 3rem;
    background: #fff;
    height: calc(100vh - 3rem);
    width: 100%;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;;
    padding: 0.68rem 0.6rem;
    .title{
      font-size: 0.24rem;
      color: #999;
      justify-content: space-between;
    }
    .bottom{
      justify-content: space-between;
      font-size: 0.3rem;
      color: #4A4A4A;
      position: relative;
      height: 0.96rem;
      line-height: 0.96rem;
      &:after{
        content: '';
        background: #EFEFF0;
        width: 100%;
        height: 0.02rem;
        position: absolute;
        bottom: 0;
      }
      div{
        flex:1;
        &:nth-child(2){
          text-align: center;
        }
        &:nth-child(3){
          text-align: right;
        }
      }
    }
  }
}
</style>